<template>
  <div class="mx-3">

    <h2 class="px-5 py-5">{{ $t('Absent Report') }}</h2>
    <v-row class="px-10 py-1">
      <v-col md="4">
        <v-autocomplete v-model="filters.registration_record_id" :label="$t('Full Name')" clearable :items="childrens"
          :loading="childrensLoading" color="#757575" item-text="name" item-value="id"
          @keypress="fetchRegRecordAutoComplete($event)">
        </v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete color="#757575" :loading="eduClassesLoading" item-text="name" item-value="id"
          :label="$t('educitional class')" v-model="filters.edu_class" :items="eduClasses" clearable></v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete color="#757575" item-text="name" item-value="id" :loading="gradesLoading" :label="$t('class')"
          v-model="filters.grade" :items="grades" clearable></v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete color="#757575" :label="$t('Absent Type')" :items="absentTypes" item-text="name"
          item-value="value" v-model="filters.type" clearable></v-autocomplete>
      </v-col>
      <v-col md="2">

      </v-col>
      <v-col md="3">
        <v-text-field :label="$t('hr.time.from-date')" type="date" v-model="filters.from_date">
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field :label="$t('hr.time.to-date')" type="date" v-model="filters.to_date">
        </v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field :label="$t('hr.employee.note')" type="text" v-model="filters.note"></v-text-field>
      </v-col>

      <v-col md="3" class="mb-3 py-0">
        <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="blue" dark block @click="exportToExcel(filters)">{{ $t('hr.time.export') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table :dark="$store.state.isDarkMode" :headers="headers" :loading="loading" :items="tableItems"
      class="elevation-1 px-10" hide-default-footer :items-per-page="10">
      <template v-slot:item.actions="{ item }">

      </template>
    </v-data-table>
    <v-pagination v-if="!loading && tableItems.length" @input="getAll" v-model="page"
      :length="Math.ceil(total / 10)"></v-pagination>
  </div>
</template>
  
<script>
import axios from "axios";
import i18n from '@/i18n';
import debounce from "../../../../../helpers/debounce";

import { saveAs } from 'file-saver';


export default {
  data: () => ({
    grades: null,
    gradesLoading: false,
    eduClasses: null,
    eduClassesLoading: false,
    childrens: null,
    childrensLoading: false,
    tableItems: [],

    loading: false,
    page: 1,
    total: 1,
    headers: [
      { text: i18n.t("name"), value: "student" },
      { text: i18n.t('class'), value: "grade" },
      { text: i18n.t('educitional class'), value: "edu_class" },
      { text: i18n.t('Absent Type'), value: "type" },
      { text: i18n.t('day'), value: "day" },
      { text: i18n.t('date'), value: "date" },
      { text: i18n.t('hr.employee.note'), value: "note" },
    ],
    items: [],
    absentTypes: [
      {
        name: "justified",
        value: "justified",
      },
      {
        name: "unjustified",
        value: "unjustified",
      },
    ],
    filters: {
      registration_record_id: null,
      edu_class: null,
      grade: null,
      type: null,
      from_date: null,
      to_date: null,
      note: null,
    },
  }),
  methods: {
    async fetchEduClasses() {
      try {
        this.eduClassesLoading = true;
        const eduClasses = await axios.get("/edu-class", {
          params: {
            paginated: false,
          }
        });
        this.eduClasses = eduClasses.data.classes;
      } catch (err) {
      } finally {
        this.eduClassesLoading = false;
      }
    },
    async fetchRegRecordAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.childrensLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/student/auto-complete", {
            params: {
              name: searchValue,
            },
          });
          this.childrens = searchResult.data.data;

        } catch (err) {
          console.log("err", err);
        } finally {
          this.childrensLoading = false;
        }
      }, 1000)();
    },
    async exportToExcel(queryParams) {
      try {
        const excel = await axios.get(`/absent/export`, {
          params: {
            ...queryParams,

          },
          responseType: 'blob'
        });
        saveAs(excel.data)
      } catch (err) {
        console.log('err', err);
      } finally {
      }
    },

    clearFilter() {
      this.filters.registration_record_id = undefined;
      this.filters.edu_class = undefined;
      this.filters.from_date = undefined;
      this.filters.to_date = undefined;
      this.filters.type = undefined;
      this.filters.note = undefined;
      this.filters.grade = undefined;
      this.page = 1;
      this.getAll();
    },

    async getAll(page) {
      try {
        // if (!page) this.page = 1;
        const filterTemp = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
        this.loading = true;

        const res = await axios.get("/absent/report", {
          params: {
            size: 10,
            page: this.page,
            ...filterTemp,
          },
        });
        const arr = Array.from(res.data.data)
        if (Array.isArray(arr)) {

          this.tableItems = arr.map((el) => {

            return {
              ...el,

            };
          });
        }
        this.page = res.data.meta.current_page;
        this.total = res.data.meta.total;


      } finally {
        this.loading = false;
      }
    },




  },
  async created() {
    this.getAll();
    this.fetchEduClasses();
    try {
      this.gradesLoading = true;
      const response = await axios.get("/grade", {
        params: {
          size: 100000,
        },
      });
      this.grades = response.data.grades;
    } catch (err) {
      console.log("err", err);
    } finally {
      this.gradesLoading = false;
    }

  },
};
</script>
  
<style></style>
  